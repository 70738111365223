import React from 'react';

import ContentWrapper from '../components/contentWrapper';
import Layout from '../components/layout';
import Footer from '../components/footer';
import SEO from '../components/seo';

const SecondPage = () => (
  <>
    <Layout>
      <SEO title="About Lovable CX" />
      <ContentWrapper>
        <SEO title="About Lovable CX" />
        <h2>What's this?</h2>
        <p>
          LovableCX is a project maintained by{' '}
          <a href="http://www.melaniecrissey.com/">Melanie Crissey</a>.
        </p>
      </ContentWrapper>
    </Layout>
    <Footer />
  </>
);

export default SecondPage;
